import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SiteInfoPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="SiteInfo" />
      <h1>Site Info</h1>
      <p><a target="_new" href="https://github.com/davenotik/OurRebbetzin.com">OurRebbetzin.com on GitHub</a></p>
      <p>(This is a private repo and you will need to be granted access.)</p>
    </Layout>
  )
}

export default SiteInfoPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`